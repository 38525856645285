<template>
  <el-row justify="start" align="top" :gutter="10" v-infinite-scroll="loadNewItems" infinite-scroll-distance="200" style="height: 70vh; overflow: auto; ">
    <el-col :xs="24" :sm="12" :md="8" :lg="24/cols" v-for="project in visibleItems" :key="project['.key']">
      <el-card
        class="box-card"
        :body-style="{ padding: '0px' }"
        @click="$router.push('/projects/' + project['.key'])"
      >
        <ImageWithOver
          :url="project.thumbnail"
          :caption="project.name"
          @click="$router.push('/project/' + project['.key'])"
        />
        <div style="padding: 4px;">
          <!-- <span>Yummy hamburger</span> -->
          <div class="bottom clearfix" style="position: relative">
            <time class="time">{{ prettyDate(project) }}</time>
            <br>
            <small>by {{formatUser(project.username) }}</small>
            <div style="float: right; margin-right: 12px; margin-top: -10px">
              <!-- <el-button type="text" class="button">View</el-button> -->
              <small>
                <img
                  src="../assets/chat.png"
                  style="width: 24px; opacity: 0.4;vertical-align: middle;"
                >
                {{project.commentsCount||0}}
              </small>
              <small>
                <img
                  src="../assets/cloud-computing.png"
                  style="width: 24px; opacity: 0.4;vertical-align: middle;"
                >
                {{project.downloadsCount||0}}
              </small>
            </div>
          </div>
        </div>
      </el-card>
    </el-col>
    <el-col v-if="items.length == 0" :span="24">
      <p>No projects in this category yet.</p>
    </el-col>
  </el-row>
</template>
<script>
import moment from "moment";
import ImageWithOver from "./ImageWithOver.vue";

export default {
  props: ["items", "cols"],
  components: {
    ImageWithOver
  },
  data() {
    return {
      numVisible: 10,
      currentDate: ""
    };
  },
  mounted() {
    if (!this.cols) {
      this.cols = 4;
    }
  },
  methods: {
    formatUser(user) {
      if (user && user.length > 10) {
        user = user.slice(0, 10) + "...";
      }
      return user;
    },
    loadNewItems() {
        this.numVisible += 10;
    },
  },
  computed: {
    noItems() {
        return this.items.length == 0;
    },
    prettyDate() {
      return function(item) {
        if (item) return moment(item.created).format("DD/MM/YYYY");
        return "";
      };
    },
    visibleItems() {
        return this.items.slice(0, this.numVisible);
    },
  }
};
</script>
<style scoped>
.card-wrap {
  /* padding: 12px; */
  padding-left: 0px;
}

.image {
  width: 100%;
  display: block;
}

.pic-wrapper {
  padding: 0px;
  margin: 0px;
}
.box-card {
  width: 100%;
  min-height: 200px;
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>

