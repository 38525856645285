<template>
    <div class="content">
        <h2 v-show="!logged">Loading...</h2>
        <el-row v-show="logged">
            <el-col :span="6">
                <h1> User profile </h1>
                <img :src="photo" style="height: 120px"> <br>
                <p>{{name}}</p>
                <p>{{email}}</p>
                <!-- <p>{{userId}}</p> -->
                <!-- <hr> -->
                <!-- <pre>{{user}}</pre> -->
            </el-col>
            <el-col :span="18">
                <h2>Your projects</h2>
                <ProjectsListing :items="projectsRev" v-if="projects.length > 0" :cols="3" />
                <div v-else>
                    <h3>Ouch, looks like you have no projects, upload one now</h3>
                    <el-button @click="$router.push({'name' : 'upload-project'})">Upload project</el-button>
                </div>
            </el-col>
        </el-row>

    </div>
</template>
<script>
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import ProjectsListing from './ProjectsListing.vue';

export default {
    components: {
        ProjectsListing
    },
    data() {
        return {
            items: ['one', 'two', 'three'],
            photo: '',
            userId: '',
            name: '',
            email: '',
            user: {},
            logged: false,
            projects: []
        }
    },
    created() {
        const _this = this;
        firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
                _this.loadProfile(user);
                // User is signed in.
            } else {
                _this.loggedOut();
                // No user is signed in.
            }
        });
    },
    computed: {
        projectsRev: function() {
            return this.projects.slice(0).reverse()
        }
    },
    methods: {
        loggedOut() {
            this.logged = false;
            // this.$router.push('/')
        },
        loadProfile(user) {
            this.user = user
            if (this.user) {
                this.logged = true
                this.name = this.user.displayName;
                this.email = this.user.email;
                this.photo = this.user.photoURL;
                this.userId = this.user.uid;
            }
            const db = firebase.database()
            const userProjectsRef =
                db.ref('/library/profiles/' + this.userId + '/projects')
                    .orderByKey()
                    .getRef();

            // if (this.projects)
            // this.$unbind('projects')
            this.$bindAsArray('projects', userProjectsRef, null, function() {
                console.log('user projects loaded')
            })
        }
    }
}
</script>
<style scoped>

</style>
